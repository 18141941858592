<template>
    <div>
      <section>
        <article class="container text-left">
          <h1>The Main Page</h1>
          <div class="cards-grid">
            <div @click="$router.push({path: '/inventory'})">
              <div class="card-details">
                <h2>Inventory</h2>
                <p class="total">15</p>
                <!-- <p class="text"><span class="span-text">The number of admins and the support team.</span></p> -->
              </div>
              <div class="card-icon icon1"><i class="el-icon-user-solid"></i></div>
            </div>
            <div @click="$router.push({path: '/POS'})">
              <div class="card-details">
                <h2>Point Of Sales</h2>
                <p class="total">20</p>
                <!-- <p class="text"><span class="span-text">The number of orders in the system</span></p> -->
              </div>
              <div class="card-icon icon2"><i class="el-icon-share"></i></div>
            </div>
            <div @click="$router.push({path: '/ClientPOS'})">
              <div class="card-details">
                <h2>Client Point Of Sales</h2>
                <p class="total">20</p>
                <!-- <p class="text"><span class="span-text">The number of orders in the system</span></p> -->
              </div>
              <div class="card-icon icon2"><i class="el-icon-share"></i></div>
            </div>
            <div @click="$router.push({path: '/bookings&reservations'})">
              <div class="card-details">
                <h2>Bookings & Reservations</h2>
                <p class="total">40</p>
                <!-- <p class="text"><span class="span-text">The number of products in the system</span></p> -->
              </div>
              <div class="card-icon icon3"><i class="el-icon-s-data"></i></div>
            </div>
            <div @click="$router.push({path: '/HRM'})">
              <div class="card-details">
                <h2>Human Resource Management</h2>
                <p class="total">34</p>
                <!-- <p class="text"><span class="span-text">The number of sales that are made online today</span></p> -->
              </div>
              <div class="card-icon icon4"><i class="el-icon-loading"></i></div>
            </div>
          </div>
        </article>
      </section>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        orders: "",
        products: "",
        users: "",
      };
    }
  };
  </script>
  
  <style scoped>
  h1 {
    font-size: 1.8em;
    font-weight: 600;
    margin-top: 40px;
    margin-bottom: 30px;
  }
  .cards-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
  }
  .cards-grid > div {
    cursor: pointer;
    border-radius: 10px;
    position: relative;
    background-color: white;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .cards-grid > div .card-icon {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 45px;
    height: 45px;
    border-radius: 15px;
    font-size: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .cards-grid > div .icon1 {
    background-color: rgba(128, 0, 128, 0.5);
    color: purple;
  }
  .cards-grid > div .icon2 {
    background-color: rgba(255, 165, 0, 0.5);
    color: orange;
  }
  .cards-grid > div .icon3 {
    background-color: rgba(0, 128, 0, 0.5);
    color: green;
  }
  .cards-grid > div .icon4 {
    background-color: rgba(165, 42, 42, 0.5);
    color: brown;
  }
  .span-text {
    color: rgba(0, 128, 0, 1);
  }
  .card-details {
    padding: 15px;
  }
  .card-details h2 {
    font-size: 0.8em;
    font-weight: 600;
    color: grey;
    padding: 0px;
    margin: 0px;
  }
  .card-details .total {
    font-size: 1.5em;
    font-weight: 600;
  }
  .card-details .text {
    font-size: 0.8em;
    padding: 0px;
    margin: 0px;
  }
  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) {
  }
  
  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  }
  
  /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
  @media (min-width: 481px) and (max-width: 767px) {
  }
  
  /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
  @media (min-width: 320px) and (max-width: 480px) {
  }
  </style>