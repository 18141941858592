var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('section',[_c('article',{staticClass:"container text-left"},[_c('h1',[_vm._v("The Main Page")]),_c('div',{staticClass:"cards-grid"},[_c('div',{on:{"click":function($event){return _vm.$router.push({path: '/inventory'})}}},[_vm._m(0),_vm._m(1)]),_c('div',{on:{"click":function($event){return _vm.$router.push({path: '/POS'})}}},[_vm._m(2),_vm._m(3)]),_c('div',{on:{"click":function($event){return _vm.$router.push({path: '/ClientPOS'})}}},[_vm._m(4),_vm._m(5)]),_c('div',{on:{"click":function($event){return _vm.$router.push({path: '/bookings&reservations'})}}},[_vm._m(6),_vm._m(7)]),_c('div',{on:{"click":function($event){return _vm.$router.push({path: '/HRM'})}}},[_vm._m(8),_vm._m(9)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-details"},[_c('h2',[_vm._v("Inventory")]),_c('p',{staticClass:"total"},[_vm._v("15")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-icon icon1"},[_c('i',{staticClass:"el-icon-user-solid"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-details"},[_c('h2',[_vm._v("Point Of Sales")]),_c('p',{staticClass:"total"},[_vm._v("20")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-icon icon2"},[_c('i',{staticClass:"el-icon-share"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-details"},[_c('h2',[_vm._v("Client Point Of Sales")]),_c('p',{staticClass:"total"},[_vm._v("20")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-icon icon2"},[_c('i',{staticClass:"el-icon-share"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-details"},[_c('h2',[_vm._v("Bookings & Reservations")]),_c('p',{staticClass:"total"},[_vm._v("40")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-icon icon3"},[_c('i',{staticClass:"el-icon-s-data"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-details"},[_c('h2',[_vm._v("Human Resource Management")]),_c('p',{staticClass:"total"},[_vm._v("34")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card-icon icon4"},[_c('i',{staticClass:"el-icon-loading"})])
}]

export { render, staticRenderFns }